/* animations.css */
@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .animate-fade-in-up {
    animation: fadeInUp 0.6s ease-out forwards;
  }
  
  .animate-slide-in {
    animation: slideIn 0.6s ease-out forwards;
  }
  
  .stagger-animate > * {
    opacity: 0;
  }
  
  .stagger-animate > *:nth-child(1) { animation-delay: 0.1s; }
  .stagger-animate > *:nth-child(2) { animation-delay: 0.2s; }
  .stagger-animate > *:nth-child(3) { animation-delay: 0.3s; }
  .stagger-animate > *:nth-child(4) { animation-delay: 0.4s; }
  .stagger-animate > *:nth-child(5) { animation-delay: 0.5s; }
  .stagger-animate > *:nth-child(6) { animation-delay: 0.6s; }
  .stagger-animate > *:nth-child(7) { animation-delay: 0.7s; }
  .stagger-animate > *:nth-child(8) { animation-delay: 0.8s; }